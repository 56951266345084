/**
 * 返回日期插件
 * @returns {string}
 */
export const dataUnit = () => {
    let newData = new Date();
    let year = newData.getFullYear(); //获取完整的年份(4位)
    let mouth = newData.getMonth()+1;
    let data = newData.getDate();
    mouth<10?mouth = '0'+mouth:mouth
    data<10?data = '0'+data:data
    return data+' / '+mouth+' / '+year
}

/**
 * 返回时间插件
 * @returns {string}
 */
export const timeUnit = () => {
    let newData = new Date();
    let hours;
    newData.getHours()<10?hours = '0'+newData.getHours():hours = newData.getHours()
    let minutes;
    newData.getMinutes()<10?minutes = '0'+newData.getMinutes():minutes = newData.getMinutes()
    return hours+':'+minutes
}
/**
 * 返回制定格式的日期
 * @param value
 * @param format
 */
export const formatUnit = (value) => {
    let month = new Date(value).getMonth()+1
    let date = new Date(value).getDate()
    if(month<10){
        month = '0'+month
    }
    if(date<10){
        date = '0'+date
    }
    return date+' / '+month
}

export const dateFormat = (fmt, date) => {
    let ret;
    const opt = {
        "Y+": date.getFullYear().toString(),        // 年
        "m+": (date.getMonth() + 1).toString(),     // 月
        "d+": date.getDate().toString(),            // 日
        "H+": date.getHours().toString(),           // 时
        "M+": date.getMinutes().toString(),         // 分
        "S+": date.getSeconds().toString()          // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
    };
    for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
            fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
        }
    }
    return fmt;
}
